.section {
    background-color: var(--secondary-bg-color);
    width: 100%;
    max-width: 750px;
    padding: 1.5rem;
    position: relative;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.section > h2 {
    margin: 1rem 0;
}

.section-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.section-header > div > a {
    display: inline-block;
}

.subsection-header {
    width: 100%;
    text-align: center;
}

.subsection-header > a {
    position: absolute;
    top: 2rem;
}

.subsection-header > button {
    position: absolute;
    top: 23px;
    right: 10px;
}

.subsection-body {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 630px) {
    .section {
        padding: 15px;
        max-width: 400px;
        border-radius: 15px;
    }

    .section-header {
        margin-bottom: 10px;
    }

    .subsection-header > a {
        top: 15px;
    }

    .subsection-header > button {
        display: block;
        position: static;
        margin: 15px auto;
    }
}

/* EDITING FORM */

.section form {
    margin: 1rem;
}

.section input,
.section select,
.section textarea {
    height: 40px;
    width: 300px;
    border: 1px solid rgb(108, 108, 108);
    border-radius: 5px;
    background-color: var(--item-bg-color);
    color: var(--main-text-color);
    text-align: center;
}

.section input[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin: 0 15px;
}

.section option:checked {
    background-color: var(--active-text-color);
}

.input-group {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.input-group button {
    height: 40px;
}

.input-group > a,
.input-group > button {
    margin-left: 0.5rem;
}

.section label {
    margin-left: 0.5rem;
}

/* .section form button { */
.form-btn {
    margin-top: 1rem;
    min-width: 150px;
    min-height: 50px;
    font-size: 1.5rem;
}

/* .section form button:nth-of-type(2) { */
.form-btn:nth-of-type(2) {
    margin-left: 2rem;
}

.divider {
    width: 100%;
    text-align: center;
    border-top: #ffc208 1px dashed;
    margin: 15px 0;
    /* background-color: aliceblue; */
}

@media (max-width: 500px) {
    .section form {
        margin: 5px;
    }

    .section input,
    .section select,
    .section textarea {
        width: 230px;
    }

    .section label {
        margin: 0;
        font-size: 12px;
    }

    .input-group {
        flex-direction: column;
        text-align: center;
    }

    .input-group:first-of-type {
        margin-top: 10px;
    }
}

/* ORDERED LIST */

ol {
    width: 100%;
    list-style: none;
    counter-reset: item;
}

.item-container {
    background-color: var(--item-bg-color);
    padding: 12px 20px;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

li .item-name-container h3::before {
    counter-increment: item;
    content: counter(item);
}

.item-info-container {
    font-size: 15px;
    display: flex;
}

.item-info-container > h5 {
    margin-left: 10px;
}

.item-info-container span {
    color: var(--secondary-text-color);
}

.buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px) {
    .item-info-container {
        font-size: 10px;
        flex-direction: column;
    }

    .item-info-container > h5 {
        margin-left: 5px;
    }

    .buttons-container {
        flex-direction: column;
    }

    .buttons-container > a {
        display: block;
        margin: 5px;
    }
}

/* NFTS */

#nfts-section {
    max-width: 1000px;
}

#nfts-section small {
    color: var(--secondary-text-color);
}

.nft-container {
    margin: 10px 0;
    padding: 7px;
    background-color: var(--item-bg-color);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 2fr;
}

.nft-main-info {
    display: flex;
    align-items: center;
}

.nft-main-info > img {
    max-height: 50px;
    border-radius: 5px;
    margin-right: 10px;
}

.layered-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-right: 10px;
}

.layered-cell svg {
    height: 25px;
    fill: #ffc208;
}

@media (max-width: 500px) {
    .nft-container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 5px;
        padding: 10px 5px;
    }

    .nft-container > div:nth-of-type(1) {
        grid-column: span 6;
    }
    .nft-container > div:nth-of-type(2) {
        grid-column: span 3;
    }
    .nft-container > div:nth-of-type(3) {
        grid-column: span 3;
    }

    .nft-container > div:nth-of-type(4) {
        grid-column: span 6;
    }
    .nft-container > .buttons-container {
        flex-direction: row;
    }

    .buttons-container button {
        margin: 0;
    }
}

/* REQUESTS */

#requests-section {
    max-width: 900px;
}

#requests-section small {
    color: var(--secondary-text-color);
}

.request-container {
    margin: 10px 0;
    padding: 7px;
    background-color: var(--item-bg-color);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr;
}

.request-info {
    text-align: left;
    align-items: flex-start;
}

.request-info > h4 {
    font-size: 15px;
}

.request-info span {
    color: var(--secondary-text-color);
}

@media (max-width: 500px) {
    .request-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .request-container > div:nth-of-type(1) {
        order: 1;
    }
    .request-container > div:nth-of-type(2) {
        order: 0;
        grid-column: span 3;
    }
    .request-info > h4 {
        font-size: 8px;
    }
}

/* INFORMATION */

.information-section {
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 25px;
    column-gap: 25px;
}

.info-card {
    position: relative;
    background-color: var(--secondary-bg-color);
    border-radius: 25px;
    padding: 25px;
}

.info-card > h4 {
    margin-bottom: 10px;
}

.card-double {
    grid-column: span 2;
}

.card-double > div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

@media (max-width: 500px) {
    .information-section {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        row-gap: 12px;
        column-gap: 12px;
    }

    .info-card {
        padding: 20px;
    }

    .card-double {
        grid-column: span 1;
    }
}

/* SEARCH */

.search-subheader {
    margin-top: 25px;
    margin-bottom: 10px;
}

/* SORTING */

.sort-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
}

.sort-container select {
    width: 100px;
    margin: 10px;
}

@media (max-width: 500px) {
    .sort-container {
        grid-template-columns: 1fr;
    }
}

/* SETTINGS */

#settings-section > .section {
    align-items: flex-start;
}

.dragable-item-container {
    position: relative;
    background-color: var(--item-bg-color);
    padding: 12px 20px;
    margin: 0.5rem 0;
    border-radius: 10px;
}

.dragable-item-container > h3 {
    margin-bottom: 10px;
}

.dragable-item-container > button {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* COMISSIONS */

.comissions > div {
    margin: 15px;
    > h4 {
        margin-bottom: 20px;
    }
}

.comissions-form > .input-group {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    > input {
        width: auto;
        text-align: center;
    }
}

/* ONE OFFS */

.login-section {
    padding: 25px;
    background-color: var(--secondary-bg-color);
    border-radius: 25px;
    max-width: 750px;
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
}

#sc-address {
    width: 400px;
}

#tags,
#categories {
    height: 100px;
}

#description {
    min-height: 100px;
}

.info-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.info-container > div {
    padding: 2rem;
    text-align: center;
    border-right: 1px solid white;
}

.info-container > div:nth-last-of-type(1) {
    border-right: none;
}

.info-container > div > h2 {
    margin-bottom: 0.5rem;
    color: aqua;
}

#file-form input {
    width: auto;
    text-align: center;
    height: auto;
}

.socials > div {
    margin-top: 1rem;
}

@media (max-width: 500px) {
    #nfts-section .section-header {
        flex-direction: column;
    }

    #nfts-section .section-header div {
        margin-top: 10px;
    }

    #nfts-section .section-header button {
        max-width: 50px;
    }

    #sc-address {
        font-size: 10px;
        width: 230px;
        margin-bottom: 10px;
    }
}
