.icon-btn span {
    display: block;
    font-weight: 600;
    font-size: 12px;
    text-transform: lowercase;
}

.icon-btn svg {
    height: 20px;
}

@media (max-width: 500px) {
    .icon-btn span {
        font-size: 9px;
    }
    .icon-btn svg {
        height: 15px;
    }
}

.update-button {
    min-width: 200px;
    margin: auto;
    padding: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.update-button > span {
    text-transform: uppercase;
    font-size: 20px;
}

.update-button > svg {
    fill: #fff;
}
