.modal-overlay {
    background-color: #0f0f0f;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    position: fixed;
}

.modal-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-bg-color);
    min-width: 350px;
    min-height: 250px;
    max-width: 80vh;
    padding: 20px;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 2px #0f0f0f;
    text-align: center;
}

.modal-container > div {
    margin-top: 20px;
}

.modal-container > button {
    margin-top: 20px;
}

.additional-info {
    width: 100%;
    margin-top: 10px;
    max-height: 100px;
    overflow-y: auto;
}

.additional-info > div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 10px;
}

.additional-info h5 {
    text-align: right;
}

.additional-info small {
    text-align: left;
}

@media (max-width: 64em) {
    .modal-container {
        width: 80vw;
    }
}
