.nav-section-container {
    padding: 1rem 0;
}

.nav-section-container h3 {
    color: var(--main-text-color);
}

.nav-link {
    padding: 0.5rem 0.75rem;
    margin: 0.25rem 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.nav-link > small {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.nav-link svg {
    height: 20px;
    margin-right: 0.75rem;
}

.nav-link path {
    fill: var(--secondary-text-color);
}

.nav-link:hover {
    background-color: var(--secondary-bg-color);
}

.nav-link:hover > small {
    color: var(--active-text-color);
}

.nav-link:hover path {
    fill: var(--active-text-color);
}

.active {
    background-color: var(--active-bg-color);
}

.active > small {
    font-weight: 700;
    color: var(--active-text-color);
}

.active:hover {
    cursor: default;
}

.active path {
    fill: var(--active-text-color);
}

@media (max-width: 500px) {
    .nav-section-container h3 {
        font-size: 15px;
    }
    .nav-link {
        padding: 5px;
        justify-content: center;
        flex-direction: column;
    }

    .nav-link > small {
        padding-top: 5px;
        text-align: center;
        font-size: 7px;
    }

    .nav-link svg {
        height: 20px;
        margin: 0;
    }
}
