.main-container {
    height: calc(100vh - var(--statusbar-height));
    display: flex;
}

.nav-container {
    margin-top: var(--statusbar-height);
    position: fixed;
    height: 100%;
    width: var(--navbar-width);
    flex-shrink: 0;
    padding: 0 1rem;
    border-right: 1px var(--secondary-bg-color) dotted;
}

.view-container {
    margin-top: var(--statusbar-height);
    margin-left: var(--navbar-width);
    max-width: calc(100vw - var(--navbar-width));
    height: 100%;
    flex-grow: 1;
    padding: 1rem 2rem;
}
