.faq-body {
    width: 100%;
}

#faq-list input,
#faq-list textarea {
    width: 100%;
}

#faq-list textarea {
    height: 150px;
}

#faq-list .dragable-item-container {
    margin: 20px 0;
}
