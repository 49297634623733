:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;

    color: var(--main-text-color);
    background-color: var(--main-bg-color);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

    /* VARIABLES */
    --main-bg-color: #171c23;
    --main-text-color: #fff;
    --secondary-bg-color: #232b35;
    --secondary-text-color: #98a1ab;
    --item-bg-color: #333d49;

    --statusbar-height: 50px;

    --navbar-width: 250px;

    --active-bg-color: #192828;
    --active-text-color: #57b561;

    --btn-bg-color: #1f98ff;
    --btn-hover-color: #1884e2;

    --btn-normal-text-color: #1f98ff;
    --btn-normal-text-hover-color: #fff;
    --btn-normal-bg-color: #1f97ff6f;
    --btn-normal-hover-color: #1f97ff61;

    --btn-submit-text-color: #57b561;
    --btn-submit-text-hover-color: #fff;
    --btn-submit-bg-color: #00ab563f;
    --btn-submit-hover-color: #00984c;

    --btn-danger-text-color: #fb514f;
    --btn-danger-text-hover-color: #7f2624;
    --btn-danger-bg-color: #fd454267;
    --btn-danger-hover-color: #e43633;

    --btn-warning-text-color: #ffc208;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    display: block;
    color: var(--secondary-text-color);
    text-decoration: none;
    font-weight: bold;
}
a:hover {
    color: var(--hover-link-color);
}

button {
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background-color: var(--btn-bg-color);
    color: var(--main-text-color);
}

button:hover {
    background-color: var(--btn-hover-color);
}

button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

@media (max-width: 500px) {
    :root {
        --navbar-width: 55px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 15px;
    }

    h4 {
        font-size: 13px;
    }

    button {
        padding: 10px;
        font-size: 10px;
    }
}

small {
    color: var(--secondary-text-color);
}

/* HELPER CLASSES */

@media (max-width: 500px) {
    .desktop-only {
        display: none;
    }
}

.btn-lg {
    min-width: 150px;
    min-height: 50px;
    font-size: 1.5rem;
}

.btn-normal {
    background-color: var(--btn-normal-bg-color);
    color: var(--btn-normal-text-color);
    fill: var(--btn-normal-text-color);
}

.btn-normal:hover {
    background-color: var(--btn-normal-hover-color);
    color: var(--btn-normal-text-hover-color);
    fill: var(--btn-normal-text-hover-color);
}

.btn-submit {
    background-color: var(--btn-submit-bg-color);
    color: var(--btn-submit-text-color);
    fill: var(--btn-submit-text-color);
}

.btn-submit:hover {
    background-color: var(--btn-submit-hover-color);
    color: var(--btn-submit-text-hover-color);
    fill: var(--btn-submit-text-hover-color);
}

.btn-danger {
    background-color: var(--btn-danger-bg-color);
    color: var(--btn-danger-text-color);
    fill: var(--btn-danger-text-color);
}

.btn-danger:hover {
    background-color: var(--btn-danger-hover-color);
    color: var(--btn-danger-text-hover-color);
    fill: var(--btn-danger-text-hover-color);
}

.btn-no-bg {
    background-color: transparent;
}

.icn {
    position: absolute;
    right: 25px;
    height: 25px;
}

.icn-response {
    height: 50px;
}

.icn-success {
    fill: var(--active-text-color);
}

.icn-danger {
    fill: var(--btn-danger-text-color);
}

.icn-warning {
    fill: var(--btn-warning-text-color);
}
