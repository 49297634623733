.status-container {
    position: fixed;
    width: 100%;
    height: var(--statusbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 0 2rem;
    background-color: var(--main-bg-color);
    z-index: 1;
}

.status-container img {
    height: 30px;
}

.status-container > div {
    flex-shrink: 0;
}

.status-container > div:nth-of-type(2) {
    flex-grow: 1;
}

@media (max-width: 500px) {
    .status-container {
        padding: 0 10px;
    }
}
