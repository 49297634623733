.partners-body,
.image-group,
#partners-list input {
    width: 100%;
}

#partners-list .dragable-item-container {
    margin: 20px 0;
}

.image-group {
    display: grid;
    place-content: center;
    padding: 25px 0;
}

.image-group > img {
    max-width: 60vw;
    max-height: 200px;
    margin: auto;
}

.image-group > input {
    height: auto;
}
