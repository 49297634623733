.form-search {
    margin-left: 150px;
    display: flex;
    width: 220px;
    height: 30px;
    position: relative;
    /* margin-right: 7.8%; */
    /* margin-right: 80px; */
}

.form-search input {
    background: rgba(255, 255, 255, 0.07);
    border: 2px solid rgba(20, 22, 27, 0.12);
    box-sizing: border-box;
    border-radius: 70px;
    font-size: 16px;
    color: var(--secondary-text-color);
    padding: 11px 22px;
}

.is_dark .form-search input {
    border: 2px solid rgba(255, 255, 255, 0.12);
}

.form-search button {
    width: 25px;
    height: 15px;
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    background: transparent;
    border: 0;
}

.form-search button svg {
    height: 15px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
}

.form-search button:hover svg {
    fill: var(--btn-hover-color);
}

.form-search input::placeholder {
    font-size: 16px;
    color: var(--color-1);
    font-weight: 400;
}

@media (max-width: 500px) {
    .form-search {
        margin-left: 50px;
    }
}
